<template>
  <div class="select-scenario-fields">
    <el-dialog 
      v-model="dialogVisible" 
      :title="$t('unitConversion.Fields')" 
      :before-close="close" 
      :visible.sync="dialogVisible"
      width="500px"
    >
      <div style="padding:0 20px">
        <el-input icon="el-icon-search" :placeholder="$t('scenarioConfig.Input Name Search')"  @input="hanleInputChange" v-model="searchName" clearable size="small" />
      </div>
      <div class="dialog-common-container">
        <el-scrollbar style="height:100%">
          <div class="fields-list">
            <template v-if="isRadio">
              <el-radio-group v-model="checkList">
                <el-radio v-for="(item,index) in fieldsListQuery" :key="index" :label="item.columnName">{{ item.translateTextName }}</el-radio>
              </el-radio-group>
            </template>
            <template v-else>
              <el-checkbox-group v-model="checkList">
                <el-checkbox 
                  v-for="(item,index) in fieldsListQuery" 
                  :key="index" 
                  :label="item.columnName"
                >{{ item.translateTextName }}</el-checkbox>
            </el-checkbox-group>
            </template>
          </div>
        </el-scrollbar>
      </div>
      <!-- 保存按钮 -->
      <div class="form-btn-section">
        <!-- <el-button color="#28C76F" size="small" @click="close" >{{$t('button.Cancel')}}</el-button> -->
        <el-button color="#28C76F" size="small"  type="success" @click="onSubmit" > {{$t('button.Save')}}</el-button>
      </div> 
    </el-dialog>
  </div>
</template>
<script>
  import $Api from "@/libs/api/system-config-unit";
  export default {
    name:'selectScenarioFields',
    components: {
    },
    props: {},
    data() {
      return {
        dialogVisible:false,
        isRadio:false,
        scenarioId:'',
        searchName:'',
        fieldsList:[],
        fieldsListQuery:[],
        checkList:[]
      }
    },
    created(){
      
    },
    mounted(){
      
    },
    methods: {
      open(scenarioId,scenarioFieldList,isRadio){
        this.scenarioId = scenarioId
        console.log('scenarioId',this.checkList)
        this.dialogVisible = true
        if(isRadio && isRadio === true){
          this.isRadio = true
        } else {
          this.isRadio = false
          if(scenarioFieldList){
            this.checkList = scenarioFieldList.split(',')
          } else {
            this.checkList = []
          }
        }
        this.getData(isRadio)
      },
      close(){
        this.dialogVisible = false
      },
      //获取场景详情
      async getData(isRadio){
        // let userData = JSON.parse(localStorage.getItem('userData'))
        let params = {
          scenarioId:this.scenarioId
        }
        try {
          let res = await $Api.getScenario(params);
          if(res.state){
            let souceData = res.data?.fieldList || []
            let filterData
            if(isRadio){
              filterData = souceData.filter(item => item.type === 'text')
            } else {
              filterData = souceData.filter(item => item.type === 'number')
            }
            this.fieldsList = filterData
            this.fieldsListQuery = filterData
          } else {
            this.$message.error(res.message)
          }
        } catch (err){
          this.$message.error(err.message)
        }
      },
      // 搜索场景字段
      hanleInputChange(e){
        let fieldsList = this.fieldsList
        let fieldArr =[]
        fieldsList.forEach(function(field){
          console.log('field---item',field)
          let index = field.translateTextName.search(e)
          if(index >-1){
            fieldArr.push(field)
          }
        })
        this.fieldsListQuery = fieldArr
      },
      // 点击保存
      onSubmit(){
        console.log('已选字段',JSON.stringify(this.checkList))
        // let arr = JSON.parse(JSON.stringify(this.checkList))
        if(this.isRadio){
          let res = this.fieldsList.filter(b => b.columnName === this.checkList)
          console.log('单选---已选字段',this.checkList)
          console.log('单选---已选字段',res)
          this.$emit('onSelectConfirm',res)
        } else{
          console.log('多选---已选字段',this.getCheckListArr())
          this.$emit('onSelectConfirm',this.getCheckListArr())
        }
        
        this.dialogVisible = false
      },
      getCheckListArr(){
        let {fieldsList,checkList} = this
        let filterData = checkList.map(function(a){
          let res = fieldsList.filter(b => b.columnName === a)
          if(res.length >0) return res[0]
        })
        return filterData
      }
    }
  }
</script>
<style lang="scss" scoped>
  .select-scenario-fields{
    .dialog-common-container{
      height: calc(50vh);
      ::v-deep .el-scrollbar__wrap{
        overflow-x: hidden;
        padding-bottom: 40px;
      }
    }
    .fields-list{
      padding: 0 30px;
      .el-checkbox-group{
        width: 100%;
        margin: 0 auto;
        display: flex;
        align-items: center;
        flex-wrap: wrap;
      }
      .el-radio-group{
        margin-top: 20px;
        .el-radio{
          margin-bottom: 20px;
        }
      }
    }
    .form-btn-section{
      width: 100%;
      padding-top: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-top: 1px solid #E5E5E5;
      ::v-deep .el-button{
        width: 110px;
      }
    }

    // 重置样式
    ::v-deep .el-dialog__body{
      padding: 20px 0;
    }
    ::v-deep .el-checkbox{
      display: block;
      width: 50%;
      padding: 20px 0px;
      box-sizing: border-box;
      margin: 0;
      text-align: left;
    }
  }

</style>