<template>
  <div class="d-flex justify-content-between flex-wrap common-page">
    <div class="d-flex align-items-center">
      <span class="text-nowrap"> {{$t("pagination.Display")}} </span>
      <el-select v-model="tableInfo.size" @change="handleSizeChange" size="small">
        <el-option
          v-for="item in [10, 20, 50, 100, 200, 500, 1000]"
          :key="item"
          :label="item"
          :value="item"
        >
        </el-option>
      </el-select>
      <span class="text-nowrap">{{$t("pagination.Strip")}}</span>
    </div>
    <el-pagination
      background
      @size-change="handlePage"
      @current-change="handlePage"
      :page-size.sync="tableInfo.size"
      :current-page.sync="tableInfo.current"
      layout="prev, pager, next"
      :total="tableInfo.total"
    >
    </el-pagination>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
  props: {
    tableInfo: {
      type: Object,
      default: () => {},
    },
  },
  methods: {
    handleSizeChange(){
      let {total,size,current} = this.tableInfo
      let totalPge = Math.ceil(total/size)
      if(current>totalPge){
        this.tableInfo.current = totalPge || 1
      }
      this.$emit("changePage");
    },
    handlePage() {
      this.$emit("changePage");
    },
  },
};
</script>
<style lang="scss" scoped>
.common-page{
  padding: 20px;
  ::v-deep .el-select{
    width: 120px;
    margin: 0 12px;
  }
  ::v-deep .el-pagination.is-background button,
  ::v-deep .el-pagination.is-background .el-pager li{
    height: 32px;
    line-height: 32px;
  }
}
</style>