<template>
  <div class="unit-conversion">
    <div class="location">
      {{ $t('Welcome') }} > {{ $t('System Configuration') }} >
      <span style="color:#3461FF">{{ $t('Unit Conversion') }}</span>
    </div>
    <div class="hd card">
      <div class="handleBtn">
        <el-button type="primary" size="small" @click="handleAdd">
          <i class="el-icon-plus" />
          {{$t('button.New')}}
        </el-button>
        <el-button type="danger" size="small" @click="handleBatchDel" :disabled="!selection()">
          <i class="el-icon-delete" />
          {{$t('button.Batch Delete')}}
        </el-button>
      </div>
    </div>
    <div class="bd card">
      <div class="table-header">
        <div>
          <span class="text">{{$t('images.Records')}}:{{pageParams.total}}</span>
          <template v-if="userData.userType ===203 || userData.userType ===204">
            <el-select v-model="searchParams.storeType" @change="getData" placeholder="" size="small">
              <el-option :label="$t('ThisStore')" value="2" />
              <el-option :label="$t('ZimenStore')" value="1" />
              <el-option :label="$t('AllStore')" value="0" />
            </el-select>
          </template>
          <!-- <el-select v-model="searchParams.storeType" @change="getData" placeholder="This Store" size="small">
            <el-option :label="$t('ThisStore')" value="2" />
            <el-option :label="$t('ZimenStore')" value="1" />
            <el-option :label="$t('AllStore')" value="0" />
          </el-select> -->
        </div>
      </div>

      <!-- 表格 -->
      <el-table
        ref="normalTable"
        :data="dataList"
        style="width: 100%"
        @sort-change="handleSort"
        max-height="500"
      >
        <el-table-column align="center" type="selection" fixed>
          <el-table-column align="center" width="55" type="selection">
          </el-table-column>
        </el-table-column>
        <el-table-column prop="store_name"  min-width="130" :label="$t('unitConversion.Store name')" header-align="center">
          <el-table-column prop="storeName" min-width="130" align="center">
            <template slot="header" slot-scope="scope">
              <el-input size="mini" v-model="searchParams.storeName" @input="getData" placeholder="" clearable></el-input>
            </template>
          </el-table-column>
        </el-table-column>
        <el-table-column prop="unit_type"  min-width="130" :label="$t('unitConversion.Unit Type')" header-align="center" sortable="custom">
          <el-table-column prop="unitType" min-width="130" align="center">
            <template slot="header" slot-scope="scope">
              <el-input size="mini" v-model="searchParams.unitType" @input="getData" placeholder="" clearable></el-input>
            </template>
          </el-table-column>
        </el-table-column>
        <el-table-column prop="factor"  min-width="130" :label="$t('unitConversion.Magnification')" header-align="center" sortable="custom">
          <el-table-column prop="factor" min-width="130" align="center">
            <template slot="header" slot-scope="scope">
              <el-input size="mini" v-model="searchParams.factor" @input="getData" placeholder="" clearable></el-input>
            </template>
          </el-table-column>
        </el-table-column>
        <el-table-column  min-width="130" :label="$t('unitConversion.Unit Info Sort')" header-align="center">
          <el-table-column prop="unitSort" min-width="130" align="center">
            <template slot="header" slot-scope="scope">
              <!-- <el-input size="mini" v-model="searchParams.scenarioUnitFieldList" @input="getData" placeholder="" clearable></el-input> -->
            </template>
          </el-table-column>
        </el-table-column>
        <el-table-column prop="unit_name"  min-width="130" :label="$t('unitConversion.Unit Name')" header-align="center" sortable="custom">
          <el-table-column prop="unitName" min-width="130" align="center">
            <template slot="header" slot-scope="scope">
              <el-input size="mini" v-model="searchParams.unitName" @input="getData" placeholder="" clearable></el-input>
            </template>
          </el-table-column>
        </el-table-column>
        <el-table-column prop="update_time" :label="$t('unitConversion.Update Time')"  sortable="custom" width="230" header-align="center">
          <el-table-column prop="updateTime" width="230" align="center">
            <template slot="header" slot-scope="scope">
              <el-date-picker
                v-model="searchParams.updateTime"
                type="daterange"
                size="mini"
                @change="handleDateChange"
                placement="bottom-start"
                value-format="yyyy-MM-dd"
              />
            </template>
          </el-table-column>
        </el-table-column>
        <el-table-column fixed="right" :label="$t('images.Action')" width="120" header-align="center">
          <el-table-column  align="center" width="120" >
            <template slot="header" ></template>
            <template slot="default" slot-scope="{row}">
              <div class="operate">
                <span class="table-icon"  @click="handleDelete(row)">
                  <i class="el-icon-delete"></i>
                </span>
                <span class="table-icon"  @click="handleEdit(row)">
                  <i class="el-icon-edit-outline"></i>
                </span>
              </div>
            </template>
          </el-table-column>
        </el-table-column>
      </el-table>

      <!-- 页码 -->
      <pagination :tableInfo="pageParams" @changePage="getData" />

      <!-- 创建 -->
      <create-unit-conversion ref="createUnitConversion" @getData="getData"></create-unit-conversion>

      <!-- 删除弹窗确认 -->
    <deleteConfirmDialog ref="deleteConfirmDialog" @onDeleteConfirm="deleteConfirm"></deleteConfirmDialog>

    </div>
  </div>
</template>
<script>
  import deleteConfirmDialog from '@/components/delete-confirm-dialog/index'
  import pagination from '@/components/pagination'
  import createUnitConversion from './components/create-unit-conversion'

  import $Api from "@/libs/api/system-config-unit";
  export default {
    name:'',
    components: {
      deleteConfirmDialog,
      pagination,
      createUnitConversion
    },
    props: {
      location: {
        type: String,
        default: 'default',
      },
    },
    data() {
      return {
        searchParams:{
          storeId:'',
          storeType: '0',
          storeName:'', //门店名称
          unitType:'', // 单位类别
          factor:'',//倍率
          unitName:'', // 单位名称
          isEffect:0,
          updateTime:['',''],
          startDate:'',
          endDate:'',
          unitSort:'',
          factorStart:'',
          factorEnd:'',
          orders:[]
        },
        pageParams:{
          current:1,//当前页
          size:10,//单页显示最大数
          total:0,
          pageSizeArr:['10','20','50','100','200','500','1000'],
        },
        userData:JSON.parse(localStorage.getItem('userData')),
        dataList:null,
        deleteData:{}
      }
    },
    created(){
      this.getData()
    },
    mounted(){
      
    },
    methods: {
      //获取数据
      async getData(){
        // let userData = JSON.parse(localStorage.getItem('userData'))
        // userData.storeId
        let storeId = localStorage.getItem('storeId')
        let {storeType,storeName,isEffect,startDate,endDate,unitType,unitName,unitSort,factorStart,factorEnd,orders} = this.searchParams
        let {current,size} = this.pageParams
        const params ={
          storeId,
          storeType,
          storeName,
          isEffect,
          startDate,
          endDate,
          unitType,
          unitName,
          unitSort,
          factorStart,
          factorEnd,
          current,
          size,
          orders
        }
        try {
          let res = await $Api.unitGetList(params);
          if(res.state){
            this.dataList = res.data.records
            this.pageParams.total = res.data.total
          } else {
            this.$message.error(res.message)
          }
        } catch (err){
          this.$message.error(err.message)
        }
      },
      // 排序
      handleSort(column){
        console.log('排序',column)
        let arr = []
        // this.searchParams.orders = column.prop + '_' + column.order;
        if(column.order){
          let str1= column.order === "ascending" ? "asc" : "desc";
          str1 = str1 + ':'+ column.prop;
          arr.push(str1)
        } else {
          arr = []
        }
        
        this.searchParams.orders = arr;
        this.getData()
      },
      // 时间选择change
      handleDateChange(val){
        console.log('时间选择---',val)
        if(val){
          this.searchParams.startDate = val[0];
          this.searchParams.endDate = val[1];
        } else {
          this.searchParams.startDate = '';
          this.searchParams.endDate =  '';
        }
        this.getData()
      },
      // 添加
      handleAdd(){
        this.$refs.createUnitConversion.open()
      },
      // 获取选中的数量
      selection() {
        return this.$refs.normalTable && this.$refs.normalTable.selection.length
      },
      // 编辑
      handleEdit(data){
        console.log('编辑的数据',data)
        this.$refs.createUnitConversion.open(data)
      },
      // 批量删除---弹窗确认
      handleBatchDel(){
        let ids =this.$refs.normalTable.selection.map(item => item.unitId).join(',')
        this.deleteData = ids
        console.log(ids)
        this.$refs.deleteConfirmDialog.open()

      },
      // 删除---弹窗确认
      handleDelete(row){
        this.deleteData = row.unitId
        this.$refs.deleteConfirmDialog.open()
      },
      // 删除---api
      async deleteConfirm(){
        let params ={
          unitIds:this.deleteData
        }
        try {
          const res = await $Api.unitBatchDelete(params)
          if(res.state){
            this.$message({
              message: this.$t('commonMessage.DeleteSucess'),
              type:'success',
            });
            this.$refs.deleteConfirmDialog.close()
            this.getData()
          } else{
            this.$refs.deleteConfirmDialog.close()
            this.$message.error(res.message)
          }
        } catch(error){
          this.$message.error(error.message)
        }
      }
    }
  }
</script>
<style lang="scss" scoped>
  .unit-conversion{
    .location{
      margin-bottom: 20px;
    }
    .hd{
      padding: 20px;
    }
    .bd{
      .table-header{
        padding: 20px;
        .text{
          margin-right: 20px;
        }
      }
      .table-icon  {
        padding:6px;
        cursor: pointer;
        font-size: 18px;
        color: #000;
      }
    }
    

    // 重置样式
    .el-date-editor.el-input__inner{
      width: 206px;
    }
  }
  .unit-conversion ::v-deep {
    .el-table
      .el-table__fixed-header-wrapper
      .el-table__header
      .is-group
      tr:nth-of-type(2)
      th:nth-of-type(1)
      .cell {
        display: none;
      }
  }

</style>