// import router from "../axios";
import router, { $post } from "../axios";

import config from "../../config/index"
const { baseUrl } = config;

export default {
  // 获取列表
  unitGetList: (param) => {  
    return $post(`${baseUrl}unit/v1/getList`, param)  
  },
  // 创建
  unitCreate: (param) => {  
    return $post(`${baseUrl}unit/v1/create`, param)  
  },
  //修改 
  unitUpdate: (param) => {  
    return $post(`${baseUrl}unit/v1/update`, param)  
  },
  // 获取单位转换详情
  unitGet: (param) => {  
    return $post(`${baseUrl}unit/v1/get`, param)  
  },
  // 场景列表-简化版
  getScenarioSimplifyList: (param) => {  
    return $post(`${baseUrl}scenario/v1/getScenarioSimplifyList`, param)  
  },
  // 获取场景下的字段--获取场景详情
  getScenario: (param) => {  
    return $post(`${baseUrl}scenario/v1/getScenario`, param)  
  },
  // 批量删除(逻辑)
  unitBatchDelete: (param) => {  
    return $post(`${baseUrl}unit/v1/batchDelete`, param)  
  },

}