<template>
  <div class="delete-confirm-dialog">
    <el-dialog
      v-model="dialogVisible"
      :title="$t('ConfirmDialog.Warning')"
      width="400px"
      :before-close="close"
      :visible.sync="dialogVisible"
    >
      <div class="delete-text">{{content || $t('ConfirmDialog.DeleteTips')}}</div>
      <template slot="footer">
        <div class="del-dialog-footer">
          <el-button @click="dialogVisible = false">{{$t('button.Cancel')}}</el-button>
          <el-button @click="handleConfirm" >{{$t('button.Confirm')}}</el-button>
        </div>
      </template>
    </el-dialog>
  </div>
</template>
<script>
  export default {
    name:'deleteConfirmDialog',
    components: {},
    props: {
      content: {
        type: String,
        default: '',
      },
    },
    data() {
      return {
        dialogVisible:false
      }
    },
    methods: {
      open(){
        this.dialogVisible = true
      },
      close(){
        this.dialogVisible = false
      },
      handleConfirm(){
       this.$emit('onDeleteConfirm')
      },
    }
  }
</script>
<style lang="scss" scoped>
.delete-confirm-dialog{
  .delete-text{
    height: 143px; 
    line-height: 143px;
    text-align: center;
  }
  .del-dialog-footer{
    margin-bottom: -40px;
    display: flex;
    border-radius: 0 0 6px 6px;
    overflow: hidden;
    .el-button--default{
      flex: 1;
      margin: 0 !important;
      border-radius: 0;
      padding: 16px 20px;
    }
    .el-button--default:last-child{
      color: #35B871;
    }
  }
}
</style>