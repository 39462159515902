<template>
  <div class="unit-conversion-create">
    <el-dialog 
      v-model="dialogVisible" 
      :title="$t('unitConversion.Unit Info')" 
      :before-close="close" 
      :visible.sync="dialogVisible"
      width="700px"
    >
      <div class="form-box">
        <el-form 
          label-width="120px"
          style="width: 100%"
          :model="formData"
          ref="form" 
          :rules="rules"
          size="small"
        >
          <el-form-item :label="$t('unitConversion.Location Store')" >
            <el-input v-model="userData.storeName" placeholder="" disabled />
          </el-form-item>
          <el-form-item :label="$t('unitConversion.Scene')" class="margin_b0"  prop="scenarioId">
            <el-select v-model="formData.scenarioId" placeholder="">
              <el-option
                v-for="item in scenarioList"
                :key="item.scenarioId"
                :label="item.translateTextName"
                :value="item.scenarioId">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item :label="$t('unitConversion.Fields')" prop="scenarioFieldList">
            <el-input 
              v-model="formData.scenarioFieldNameList" 
              suffix-icon="el-icon-more"
              placeholder=""
              readonly
              clearable
              :disabled="formData.scenarioId === ''"
            />
            <div v-if="formData.scenarioId" class="cover" @click="selectFields"></div>
          </el-form-item>
          <el-form-item :label="$t('unitConversion.Unit Type')" prop="unitType">
            <el-input 
              v-model="formData.unitType" 
              :placeholder="$t('formValidate.required fields')"
              clearable 
            />
          </el-form-item>
          <!-- 倍率 -->
          <el-form-item :label="$t('unitConversion.Magnification')" prop="factor">
            <el-input 
              v-model="formData.factor" 
              @input="e => this.inputChange(e,'factor')"
              :placeholder="$t('unitConversion.Placeholder')"
              clearable 
            />
            <!-- <el-input-number 
              v-model="formData.factor" 
              controls-position="right" 
              @change="handleChange"
            >
            </el-input-number> -->
          </el-form-item>
          <!-- <el-form-item :label="$t('unitConversion.Unit Info Sort')" prop="unitSort">
            <el-input 
              v-model="formData.unitSort" 
              :placeholder="$t('unitConversion.Placeholder')"
              clearable
              @input="e => this.inputChange(e,'unitSort')"
            />
          </el-form-item> -->
          <!-- 单位信息 -->
          <el-form-item :label="$t('unitConversion.Unit Info Sort')" prop="scenarioUnitFieldList">
            <el-input 
              v-model="formData.scenarioUnitFieldListName" 
              suffix-icon="el-icon-more"
              placeholder=""
              readonly
              clearable
              :disabled="formData.scenarioId === ''"
            />
            <div v-if="formData.scenarioId" class="cover" @click="selectFieldsRadio"></div>
          </el-form-item>
          <el-form-item :label="$t('unitConversion.Unit Name')" prop="unitName">
            <el-input 
              v-model="formData.unitName" 
              :placeholder="$t('formValidate.required fields')"
              clearable 
            />
          </el-form-item>
          <el-form-item :label="$t('unitConversion.Unit Visible')"  prop="showUnit">
            <el-select v-model="formData.showUnit"  size="small">
              <el-option :label="$t('unitConversion.Visible')" :value="1" />
              <el-option :label="$t('unitConversion.Invisible')" :value="0" />
            </el-select>
          </el-form-item>
        </el-form>

        <!-- 保存按钮 -->
        <div class="form-btn-section">
          <el-button color="#28C76F" size="small" @click="close" >{{$t('button.Cancel')}}</el-button>
          <el-button color="#28C76F" size="small"  type="success" @click="onSubmit" > {{$t('button.Save')}}</el-button>
        </div>

      </div>
    </el-dialog>

    <!-- 选择场景字段 -->
    <selectScenarioFields ref="selectScenarioFields" @onSelectConfirm="selectFieldsConfirm"></selectScenarioFields>
  
  </div>
</template>
<script>
  import selectScenarioFields from "./select-scenario-fields.vue";
  import $Api from "@/libs/api/system-config-unit";

  var loading
  export default {
    name:'',
    components: {
      selectScenarioFields
    },
    props: {
      location: {
        type: String,
        default: 'default',
      },
    },
    data() {
      return {
        userData:JSON.parse(localStorage.getItem('userData')),
        dialogVisible: false,
        formData:{
          unitId:'',
          factor:null, // 倍率
          unitType:'', // 单位类型
          unitName:'', // 单位名称
          scenarioUnitFieldList:'', // 计量单位号
          scenarioUnitFieldListName:'',
          scenarioId:'', // 场景ID
          scenarioFieldList:'', // 场景字段，多个以,隔开
          scenarioFieldNameList:'',// 场景名称字段，多个以,隔开
          showUnit:1, // 是否显示单位(0:不显示1:显示)
          locationStore:''
        },
        initFormData:{
          unitId:'',
          factor:null, // 倍率
          unitType:'', // 单位类型
          unitName:'', // 单位名称
          scenarioUnitFieldList:'', // 计量单位号
          scenarioUnitFieldListName:'',
          scenarioId:'', // 场景ID
          scenarioFieldList:'', // 场景字段，多个以,隔开
          scenarioFieldNameList:'',// 场景名称字段，多个以,隔开
          showUnit:1, // 是否显示单位(0:不显示1:显示)
          locationStore:''
        },
        scenarioList:[],
        rules:{
          scenarioId: [
            { required: true, message: this.$t('formValidate.required fields'), trigger: 'blur' }
          ], 
          unitType: [
            { required: true, message: this.$t('formValidate.required fields'), trigger: 'blur' },
            { max: 100, message: this.$t('formValidate.maxLength'), trigger: 'blur'}
          ], 
          scenarioFieldList: [
            { required: true, message: this.$t('formValidate.required fields'), trigger: 'change' }
          ], 
          factor: [
            { required: true, message: this.$t('formValidate.required fields'), trigger: 'blur' },
            { max: 20, message: this.$t('formValidate.maxLength20'), trigger: 'blur'}
          ], 
          scenarioUnitFieldList: [
            { required: true, message: this.$t('formValidate.required fields'), trigger: 'blur' }
          ], 
          unitName: [
            { required: true, message: this.$t('formValidate.required fields'), trigger: 'blur' },
            { max: 100, message: this.$t('formValidate.maxLength'), trigger: 'blur'}
          ], 
        }
      }
    },
    created(){
      
    },
    mounted(){
      
    },
    methods: {
      open(editData){
        this.dialogVisible=true
        this.getData()
        if(editData && editData.unitId){
          let data={
            ...editData,
            scenarioFieldNameList:'',
            scenarioUnitFieldListName:''
          }
          data.factor = data.factor.toString()
          this.formData = data
          this.getScenario(editData.scenarioId,editData.scenarioFieldList,editData.scenarioUnitFieldList)
        } else {
          this.$refs.form.resetFields()
          let obj = {...this.initFormData}
          this.formData = obj
        }
      },
      close(){
        this.$refs.form.resetFields()
        this.dialogVisible=false
      },
      //获取场景列表数据
      async getData(){
        let userData = JSON.parse(localStorage.getItem('userData'))
        let params = {
          merchantId: userData.merchantId,
          textKey:"",
          scenarioKey: "",
          isEffect: 0,
          agentId: ""
        }
        try {
          let res = await $Api.getScenarioSimplifyList(params);
          if(res.state){
            this.scenarioList = res.data
          } else {
            this.$message.error(res.message)
          }
        } catch (err){
          this.$message.error(err.message)
        }
      },
      //获取场景字段详情
      async getScenario(scenarioId,scenarioFieldList,scenarioUnitFieldList){
        // let userData = JSON.parse(localStorage.getItem('userData'))
        let params = {
          scenarioId:scenarioId
        }
        try {
          let res = await $Api.getScenario(params);
          if(res.state){
            let souceData = res.data?.fieldList || []
            if(souceData.length ===0 ){
              // 提示信息---
              return
            }
            let scenarioFieldListArr = scenarioFieldList.split(',')
            let filterData = scenarioFieldListArr.map(function(a){
              let res = souceData.filter(b => b.columnName === a)
              if(res.length >0) return res[0].translateTextName
            })
            if(scenarioUnitFieldList){
              let filterArr = souceData.filter(b => b.columnName === scenarioUnitFieldList)
              this.formData.scenarioUnitFieldListName = filterArr[0].translateTextName
            }
            this.formData.scenarioFieldNameList = filterData.join(',')
          } else {
            this.$message.error(res.message)
          }
        } catch (err){
          this.$message.error(err.message)
        }
      },
      // 选择场景字段弹窗 --复选
      selectFields(){
        this.isRadio = false
        console.log('点击选择场景字段')
        this.$refs.selectScenarioFields.open(this.formData.scenarioId,this.formData.scenarioFieldList)
      },
      // 选择场景字段---单选
      selectFieldsRadio(){
        this.isRadio = true
        this.$refs.selectScenarioFields.open(this.formData.scenarioId,this.formData.scenarioUnitFieldList,true)
      },
      // 选择场景字段---确认
      selectFieldsConfirm(arr){
        arr = arr.filter(item => item!== undefined)
        if(this.isRadio){
          // 单选
          let formData = {
            ...this.formData,
            scenarioUnitFieldList: arr.map(item => item.columnName).join(','),
            scenarioUnitFieldListName:arr.map(item => item.translateTextName).join(',')
          }
          console.log('qqq',arr.map(item => item.translateTextName).join(','))
          this.formData =  formData
        } else {
          // 多选
          let temp = arr.map(item => item.translateTextName).join(',')
          console.log('已经选择的场景字段',temp)
          this.formData.scenarioFieldNameList = temp
          this.formData.scenarioFieldList = arr.map(item => item.columnName).join(',')
        }
        
      },
      // 倍率--input事件
      inputChange(value,key) {
        let value1 = value.replace(/[^\d\.]/g, "");
        console.log("---targetStoreInputChange", value1);
        if (value1 <= 0) {
          value1 = "";
        }
        if(value1.startsWith('.')){
          value1 = '0'+value1
        }
        this.formData[key] = value1;
      },
      // 表单提交
      onSubmit(){
        loading = this.$loading({
          text:"loading...",
          lock: true,
          background: 'rgba(0, 0, 0, 0.7)',
        })
        this.$refs.form.validate( valid => {
          if (valid) {
            console.log('表单校验通过',)
            this.createUnit()
          } else {
            loading.close()
            console.log('error submit!!');
            return false
          }
        })
      },
      // 创建、修改 --单位转换api
      async createUnit(){
        let { unitId, factor, unitType,unitName,scenarioUnitFieldList,scenarioId,scenarioFieldList,showUnit } = this.formData
        let params = {
          factor, // 倍率
          unitType, // 单位类型
          unitName, // 单位名称
          scenarioUnitFieldList, // 计量单位号
          scenarioId, // 场景ID
          scenarioFieldList, // 场景字段，多个以,隔开
          showUnit, // 是否显示单位(0:不显示1:显示)
        }
        
        let res = null
        try {
          if(unitId){
            params.unitId = unitId
            res = await $Api.unitUpdate(params);
          } else {
            params.storeId = localStorage.getItem('storeId')
            res = await $Api.unitCreate(params);
          }
          if(res.state){
            let message = this.$t('commonMessage.CreateSucess')
            if(unitId){
              message = this.$t('commonMessage.ModifySucess')
            }
            this.$message({
              message: message,
              type:'success',
            });
            loading.close()
            this.close()
            this.$emit('getData')
          } else {
            loading.close()
            this.$message.error(res.message)
          }
        } catch (err){
          loading.close()
          this.$message.error(err.message)
        }
        
      }

    }
  }
</script>
<style lang="scss" scoped>
  .unit-conversion-create{
    .form-box{
      width: 480px;
      margin: 0 auto;
      min-height: 300px;
    }
    .form-btn-section{
      width: 100%;
      margin-top: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      ::v-deep .el-button{
        width: 110px;
      }
    }
    .cover{
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      cursor: pointer;
      z-index: 2;
    }

    // 重置样式
    .el-select{
      width: 100%;
    }
    .el-input-number{
      width: 100%;
      ::v-deep .el-input__inner{
        text-align: left!important;;
      }
    }
  }
</style>